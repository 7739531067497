import { createRouter, createWebHistory } from 'vue-router';


import DocRegistration from "@/pages/docs/DocRegistration";
import RequestRegistration from "@/pages/requests/RequestRegistration";
import NotFound from "@/pages/NotFound";

// import NotFound from './pages/NotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //  TODO: ez lehet hogy nem is kell, elvégre mailből kapja a linket
    { path: '/', redirect: '/felmeres/thanks' },
    { path: '/felmeres/', redirect: '/felmeres/thanks' },
    { path: '/felmeres/thanks', component: DocRegistration },
    { path: '/felmeres/docregister', component: DocRegistration },
    { path: '/felmeres/form/:uid', component: RequestRegistration, props: true },
    // { path: '/thanks', component: DocRegistration, meta: { requiresAuth: true } },
    // { path: '/docregister', component: DocRegistration, meta: { requiresAuth: true } },
    // { path: '/form/:uid', component: RequestRegistration, props: true, meta: { requiresAuth: true } },
    { path: '/felmeres/:notFound(.*)', component: NotFound }
  ]
});


export default router;
