export default {
  // registerDoc(state, payload) {
  //   state.docs.push(payload);
  // },
  // setDocs(state, payload) {
  //   state.docs = payload;
  // },
  // setDoc(state, payload) {
  //   state.doc = payload;
  // },
  setUser(state, payload) {
    state.userData = payload;
  },
  setParam(state, payload) {
    state.userParam = payload;
  },
  setListPage(state, payload) {
    state.docsListPageParams = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
};
