import { createApp } from 'vue'
import router from './router.js';
import store from './store/index.js';
import App from './App.vue'

import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from '@/components/ui/BaseSpinner';
import BaseDialog from '@/components/ui/BaseDialog';
// createApp(App).mount('#app')
const app = createApp(App)

app.use(router);
// {
//     router.setHeader(
//         'Content-Security-Policy-Report-Only',
//         "default-src 'self'; font-src 'self'; connect-src 'self' https://felmeresbackend.szivkorhaz.hu; script-src 'self'; style-src 'self' https://fonts.googleapis.com https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css; frame-src 'self'"
//     );
// }
app.use(store);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);

app.mount('#app');
