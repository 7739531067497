export default {

  async registerRequest(context, data) {
    // const userData=this.$store.getters["docs/userData"];
    console.log(data);
    let answerint = parseInt(data.answer_int);
    if (isNaN(answerint)) {
      answerint=0;
    }

    let answerselect = parseInt(data.answer_select);
    // if (isNaN(answerselect)) {
    //   answerselect=1;
    // }

    let answerbool = data.answer_bool;
    if (answerbool==true) {
      answerbool=true;
    } else {
      answerbool=false;
    }

    const requestData = {
      answer_bool: answerbool,
      answer_str: data.answer,
      answer_int: answerint,
      answer_select: answerselect,
      question: parseInt(data.id),
      filler: data.filler,
    };

    const header = { "Content-Type": "application/json" };

    const response = await fetch(`https://felmeresbackend.szivkorhaz.hu/s/answer/`, {
    // const response = await fetch(`https://felmeres.szivkorhaz.hu/s/answer/`, {
    // const response = await fetch(`http://195.181.215.97:8010/s/answer/`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      const error = new Error(response.message || 'Failed to fetch!');

      throw error;
    }
    await this.loadRequests

  },

  async registerRequestInFiller(context, data) {

    const requestData = {
      filled: data.metadata,
      //kell egy előfeldolgozás, ha answer_int nem szám, akkor nem teszi bele
      fill: 1
    };

    const header = { "Content-Type": "application/json" };

    const response = await fetch(`https://felmeresbackend.szivkorhaz.hu/s/filler/${data.filler}/?fid=${data.filleruid}`, {
    // const response = await fetch(`https://felmeres.szivkorhaz.hu/s/filler/${data.filler}/?fid=${data.filleruid}`, {
    // const response = await fetch(`http://195.181.215.97:8010/s/filler/${data.filler}/?fid=${data.filleruid}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      const error = new Error(response.message || 'Failed to fetch!');

      throw error;
    }

    await this.loadRequests
  },
  // async loadRequests(context, payload) {
  //   //ha nem telt el elég idő a legutóbbi letöltés óta, nem frissít adatokat
  //   if (!payload.forceRefresh && !context.getters.shouldUpdate) {
  //     return;
  //   }
  //   let link = 'http://195.181.215.97:8000/ipa/trans/?'
  //   if (payload.link) {
  //     link = payload.link + '&'
  //   }
  //   if (payload.page_size) {
  //     link += `page_size=${payload.page_size}&`;
  //   }
  //   if (payload.page) {
  //     link += `page=${payload.page}&`;
  //   }
  //   if (!payload.cats_filter=="") {
  //     link += `categories=${payload.cats_filter}&`;
  //   }
  //
  //   const cucc = context.rootGetters.getInit;
  //
  //   const response =await fetch(link, cucc);
  //
  //   const responseData = await response.json();
  //
  //   if (!response.ok) {
  //     const error = new Error(responseData.message || 'Failed to fetch!');
  //     throw error;
  //   }
  //
  //   const requests = [];
  //
  //   for (const item in responseData.results) {
  //     const request = {
  //       id: responseData.results[item].id,
  //       doc: responseData.results[item].doc,
  //       inTxt: responseData.results[item].in_txt,
  //       metaData: responseData.results[item].metadata,
  //       recipients: responseData.results[item].recipients,
  //       timestamp: responseData.results[item].rogz_ido,
  //     };
  //     requests.push(request);
  //   }
  //
  //   const pageParams = {
  //     countItems: responseData.count,
  //     nextPage: responseData.next,
  //     prevPage: responseData.previous,
  //     actualPage: responseData.actual_page,
  //     totalPages: responseData.total_pages,
  //     pageSize: 5
  //   }
  //
  //   context.commit('setListPage', pageParams);
  //   context.commit('setRequests', requests);
  //   context.commit('setFetchTimestamp');
  // },
  // async loadRequest(context, payload) {
  //   //ha nem telt el elég idő a legutóbbi letöltés óta, nem frissít adatokat
  //   if (!payload.forceRefresh && !context.getters.shouldUpdate) {
  //     return;
  //   }
  //   let link = `http://195.181.215.97:8000/ipa/trans/${payload.id}`
  //
  //   const cucc = context.rootGetters.getInit;
  //
  //   const response =await fetch(link, cucc);
  //   console.log(cucc);
  //   const responseData = await response.json();
  //
  //   if (!response.ok) {
  //     const error = new Error(responseData.message || 'Failed to fetch!');
  //     throw error;
  //   }
  //
  //
  //   const request = {
  //     id: responseData.id,
  //     doc: responseData.doc,
  //     inTxt: responseData.in_txt,
  //     metaData: responseData.metadata,
  //     recipients: responseData.recipients,
  //     timestamp: responseData.rogz_ido,
  //   };
  //   console.log(request);
  //
  //   context.commit('setRequest', request);
  //   context.commit('setFetchTimestamp');
  // },
  // async loadRequestNoCache(context, payload) {
  //
  //   let link = `http://195.181.215.97:8000/ipa/trans/${payload.id}`
  //
  //   const cucc = context.rootGetters.getInit;
  //
  //   const response =await fetch(link, cucc);
  //   const responseData = await response.json();
  //
  //   if (!response.ok) {
  //     const error = new Error(responseData.message || 'Failed to fetch!');
  //     throw error;
  //   }
  //
  //
  //   const request = {
  //     id: responseData.id,
  //     doc: responseData.doc,
  //     inTxt: responseData.in_txt,
  //     metaData: responseData.metadata,
  //     recipients: responseData.recipients,
  //   };
  //
  //   return request;
  // }
};
