<template>
  <form @submit.prevent="submitForm">

    <section v-if="docDa.length>0">
      <pre>{{ docDa2 }}</pre>
      <div class="form-control" :class="{invalid: !metaData.isValid}" @blur='clearValidity("metadatas")'>


        <div v-for="dat in docDa" :key="dat.key">
          <div class="card">
          <label :for="dat.key"> {{ metaData.val[dat.key].description }} : </label>

          <template v-if="metaData.val[dat.key].answer_type=='str'">

            <textarea :id="dat.key" rows="5" v-model.trim="metaData.val[dat.key].answer"></textarea>

          </template>
            <template v-else-if="metaData.val[dat.key].answer_type=='bool'">
              <div class="row">
              <div class="column2 point">
                <label :for="dat.key">Igen</label>
                <input type="radio" :id="dat.key" v-model="metaData.val[dat.key].answer_bool" value="1">
              </div>
              <div class="column2 point">
                <label :for="dat.key">Nem</label>
                <input type="radio" :id="dat.key" v-model="metaData.val[dat.key].answer_bool" value="0">
              </div>
              </div>
            </template>

            <template v-else-if="metaData.val[dat.key].answer_type=='select'">

                <div class="row">
                    <div v-for="elem in metaData.val[dat.key].select_answers" class="column2 point" :key="elem">

                        <label :for="elem">{{ elem.name }}</label>
                        <input type="radio" :id="elem.id" :value="elem.id" v-model="metaData.val[dat.key].answer_select">
                        <!-- <input type="radio" :id="elem.serial_number" :value="elem.serial_number" v-model="metaData.val[dat.key].answer_select">-->
                         <br>

                     </div>
                 </div>


             </template>

 <!--          </template>
             <template v-else-if="metaData.val[dat.key].answer_type=='bool'">
               <label :for="dat.key">Igen</label>
               <input type="radio" :id="dat.key" v-model="metaData.val[dat.key].answer_bool" v-bind:value="true">
               <label :for="dat.key">Nem</label>
               <input type="radio" :id="dat.key" v-model="metaData.val[dat.key].answer_bool" v-bind:value="false">

             </template>
 -->
          <template v-else>

                <div class="row">
                <div v-for="eleme in 5" class="column point" :key="eleme">

                  <label :for="eleme">{{ eleme }}</label>
                  <input type="radio" :id="eleme" :value="eleme" v-model="metaData.val[dat.key].answer_int">
                  <br>

                </div>
                  </div>

          </template>

          </div>
        </div>
        <p v-if="!metaData.isValid">A tagek nem lehetnek üresek.</p>

      </div>
    </section>

    <p v-if="!formIsValid">Kérlek javítsd a hibákat, és küldd újra..</p>

    <p>

    </p>
    <div class="point">
    <base-button>Küldés</base-button>
    </div>

  </form>
</template>

<script>


// const getRecipientsList = async name => {
//   return new Promise(resolve => {
//     const xhr = new XMLHttpRequest();
//     xhr.open("GET", `http://195.181.215.97:8000/ipa/email/?searchbyname=${name}`);
//     xhr.send();
//     xhr.onloadend = () => {
//       try {
//         resolve(JSON.parse(xhr.response).results);
//       } catch (error) {
//         resolve([]);
//       }
//     };
//   });
// };

export default {
  components: {
  },
  emits: ["save-data"],
  props: ["uid"],
  data() {
    return {
      model: [],
      options: [],
      visibleOptions: [],
      loadingCount: 0,
      recipientsList: [],
      searchInput: "",
      optionss: [{ country: "USA", code: "US" }],
      docDa: [],
      docDa2: "",
      docName: {
        val: "",
        isValid: true
      },
      inTxt: {
        val: "",
        isValid: true
      },
      metaData: {
        val: [],
        isValid: true
      },
      docRecipients: {
        val: [],
        isValid: true
      },
      filedata: "",
      formIsValid: true
    };
  },
  computed: {
    // loading() {
    //   return this.loadingCount !== 0;
    // },
    // modell() {
    //   let lista = "";
    //   for (const item in this.docRecipients.val) {
    //     console.log(item);
    //     if (lista === "") {
    //       lista += this.docRecipients.val[item].nev;
    //     } else {
    //       lista += `, ${this.docRecipients.val[item].nev}`;
    //     }
    //     // const elem=JSON.parse(item);
    //     // lista += elem + " ";
    //   }
    //   return lista;
    //   // return this.model;
    // },
    // filteredRecipients() {
    //   const recipients = this.$store.getters["recipients/recipients"];
    //   return recipients;
    // },
    // filteredDocs() {
    //   // console.log(this.$store.getters['docs/docs'])
    //   return this.$store.getters["docs/docs"];
    // },
    userData() {
      return this.$store.getters["docs/userData"];
    },
  },
  created() {
    this.loadUser(this.uid);
    this.handleSearchInput();
  },
  methods: {
    // onFileChange() {
    //   const files = this.$refs.file.files;
    //   this.filedata = files[0];
    //   console.log(this.filedata);
    // },
    async handleSearchInput(event) {
      if (event) {
        this.searchInput = event.target.value;
        if (this.searchInput === "") {
          this.visibleOptions = this.model;
          return;
        }

        this.load();
        // this.visibleOptions = ["foundOptions"];
        // const currentSearchInput = this.searchInput;
        // const foundOptions = await getRecipientsList(this.searchInput);
        // console.log(foundOptions);
        // if (currentSearchInput === this.searchInput) {
        //   this.options = this.model.concat(foundOptions);
        //   this.option = Array.from(new Set(this.options));
        //   this.visibleOptions = foundOptions;
        //   this.visibleOptions = this.option;
        // }
        this.unload();
      }
    },
    load() {
      ++this.loadingCount;
    },
    unload() {
      --this.loadingCount;
    },
    async loadUser(uid = this.uid) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("docs/loadUser", { uid: uid }).then(this.docName.val = this.userData.surveyId);
        this.filteredMetadatas();
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      if (this.userData.fill) {
        //TODO valamilyen köszönőoldalra kellene irányítani
        this.$router.replace(`/`);
      }
      this.isLoading = false;
    },
    async filteredMetadatas() {
      console.log('van');
      if (this.userData.surveyId != "") {
        await fetch(`https://felmeresbackend.szivkorhaz.hu/s/survey/?surveyid=${this.userData.surveyId}`).then(response => response.json())
        // await fetch(`https://felmeres.szivkorhaz.hu/s/survey/?surveyid=${this.userData.surveyId}`).then(response => response.json())
        // await fetch(`http://195.181.215.97:8010/s/survey/?surveyid=${this.userData.surveyId}`).then(response => response.json())
            .then((data) => {
              const meta = [];
              for (const key in data) {
                // console.log(key + ": ");
                const line = {
                  key: key,
                  val: ""
                };
                meta.push(line);
              }
              this.docDa = meta;
              data.filler=this.userData.filler;
              console.log('dddd');
              console.log(data);
              this.metaData.val = data;
            });
      }
    },
    validateForm() {
      this.formIsValid = true;
      if (this.docName.val === "") {
        this.formIsValid = false;
        this.docName.isValid = false;
      }

      if (this.metaData.val === "") {
        this.formIsValid = false;
        this.metaData.isValid = false;
      }
    },
    submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }
      let recips = [];
      for (const { id } of JSON.parse(JSON.stringify(this.docRecipients.val))) {
        console.log(id);
        recips.push(id);
      }
      const formData = {
        filler: this.userData.id,
        filleruid: this.uid,
        doc: this.docName.val,
        metadata: JSON.parse(JSON.stringify(this.metaData.val)), //TODO stringify helyett kézzel kellene összerakni, mert nem szereti az idézőjeleket a django, minta az actions-ben
      };
      console.log(formData);
      this.$emit("save-data", formData);
    },
  }
};
</script>

<style scoped>


.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 20%;
  padding: 0 10px;
}

.column2 {
    float: left;
    width: 50%;
    padding: 0 10px;
}
/* Remove extra left and right margins, due to padding in columns */
.row {margin: 0 -5px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.point {
  text-align: center;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  margin: 20px;
  /*padding-top: 25px;*/
  text-align: left;
  background-color: #f1f1f1;
}

/* Responsive columns - one column layout (vertical) on small screens */
/*@media screen and (max-width: 600px) {*/
/*  .column {*/
/*    width: 100%;*/
/*    display: block;*/
/*    margin-bottom: 20px;*/
/*  }*/
/*}*/
</style>
