<template>
  <section>
  <base-card>
    <h2>Ilyen oldalt nem találtam</h2>
<!--    <p>Ez az oldal nem található - lehet hogy az <router-link to='/requests'>események</router-link> oldalt keresed.</p>-->
<!--    <h2>Page not found</h2>-->
<!--    <p>This page could not be found - maybe check out all our <router-link to='/requests'>requests</router-link>.</p>-->
  </base-card>
  </section>
</template>
<script>
export default {
}
</script>
