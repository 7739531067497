import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      userData: {
        surveyId: 0,
      },
      userParam: {
        id: 1,
        page_of_thank_you: "Köszönjük!",
      },
      lastFetch: null,
      docs: [],
      docsListPageParams: {
        actualPage: 1,
        totalPages:1,
        countItems:1,
        pageSize: 5,
        options: {
          chunks: 8,
          chunksNavigation: "scroll",
          texts: {
            count: "",
            first: "",
            last: ""
          },
          edgeNavigation: true
        }
      }
    };
  },
  mutations,
  actions,
  getters
};
