<template>
  <header>
<!--    <meta http-equiv="Content-Security-Policy" content="connect-src https://felmeresbackend.szivkorhaz.hu">-->
    <nav>
      <h1>
        <a href="https://www.szivkorhaz.hu/">Balatonfüredi Állami Szívkórház</a>
<!--        <router-link to="/">Doksi</router-link>-->
      </h1>
<!--      <ul>-->
<!--        <li>-->
<!--          <router-link to="/docsi">Dokumentumok</router-link>-->
<!--&lt;!&ndash;          <router-link to="/docsi">Docs</router-link>&ndash;&gt;-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link to="/cats">Kategóriák</router-link>-->
<!--&lt;!&ndash;          <router-link to="/cats">Categories</router-link>&ndash;&gt;-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link to="/requests">Események</router-link>-->
<!--&lt;!&ndash;          <router-link to="/requests">Requests</router-link>&ndash;&gt;-->
<!--        </li>-->
<!--        <li>-->
<!--        <router-link to="/recipients">Címzettek</router-link>-->
<!--&lt;!&ndash;        <router-link to="/recipients">Recipients</router-link>&ndash;&gt;-->
<!--      </li>-->
<!--&lt;!&ndash;        <li v-if="isLoggedIn">&ndash;&gt;-->
<!--&lt;!&ndash;          <base-button @click="logout">Kilépés</base-button>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <base-button @click="logout">Logout</base-button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--      </ul>-->
    </nav>
  </header>
</template>

<script>
export default {
  // computed: {
  //     isLoggedIn() {
  //       return this.$store.getters.isAuthenticated
  //     }
  // },
  // methods: {
  //   logout() {
  //     this.$store.dispatch('logout');
  //     this.$router.replace('/auth');
  //   }
  // }
};
</script>


<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #9ad9ea;
  /*background-color: #3d008d;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #f391e3;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}
</style>
