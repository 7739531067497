export default {

  // setRequests(state, payload) {
  //   state.requests = payload;
  // },
  // setRequest(state, payload) {
  //   state.request = payload;
  //   // console.log(state.request);
  // },
  setListPage(state, payload) {
    state.requestsListPageParams = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
};
