import { createStore } from 'vuex';

import docsModule from './modules/docs/index.js';
import requestsModule from './modules/requests/index.js';


const store = createStore({
  modules: {
    docs: docsModule,
    requests: requestsModule,
  },
});

export default store;
