export default {

  requests(state) {
    console.log(state.requests);
    return state.requests;
  },
  request(state) {
    return state.request;
  },
  requestsListPageParams(state) {
    return state.requestsListPageParams;
  },
  // hasRequests(_, getters) {
  //   // return state.requests && state.requests.length > 0;
  //   //azért lett lecserélve, hogy már lemenjen a getters,
  //   // és már a fenti filtered requests-ben nézze
  //   return getters.requests && getters.requests.length > 0;
  // },
  hasRequest(state) {
    return state.request && state.request.id !==0;
  },
};
