export default {
  // async registerDoc(context, data) {
  //   const docData = {
  //     nev: data.name,
  //     in_txt: data.txt,
  //     categories: data.categories
  //   };
  //
  //
  //   const token = context.rootGetters.token;
  //   const header = { "Content-Type": "application/json", "Authorization": "Token " + token };
  //
  //   console.log(header);
  //
  //   const response = await fetch(`http://195.181.215.97:8000/ipa/docs/`, {
  //     method: "POST",
  //     headers: header,
  //     body: JSON.stringify(docData)
  //   });
  //
  //   // console.log(response)
  //   // const responseData = await response.json();
  //
  //   if (!response.ok) {
  //     const error = new Error(response.message || "Failed to fetch!");
  //     throw error;
  //   }
  //
  //   // context.commit("registerDoc", {
  //   //   ...docData
  //   //   // id: userId
  //   // });
  // },
  async loadUser(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }
    let link = "https://felmeresbackend.szivkorhaz.hu/s/filler/?";
    // let link = "https://felmeres.szivkorhaz.hu/s/filler/?";
    // let link = "http://195.181.215.97:8010/s/filler/?";
    if (payload.uid) {
      link += `fid=${payload.uid}&`;
    }
    console.log(link);

    // const header = { "Content-Type": "application/json" };
    const header = { "Content-Type": "application/json",  "Content-Security-Policy": "connect-src 'self' felmeresbackend.szivkorhaz.hu" };
    const cucc = { method: "GET", headers: header };

    console.log(cucc);
    const response = await fetch(link, cucc);
    const responseData = await response.json().then(u=> {

      const doc = {
        id: u[0].id,
        surveyId: u[0].survey,
        uId: u[0].uid,
        fill: u[0].fill,
      };
      console.log(doc);
      context.commit("setUser", doc);
    });

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

  },
  async loadParam(context) {

    let link = "https://felmeresbackend.szivkorhaz.hu/s/param/1";
    // let link = "https://felmeres.szivkorhaz.hu/s/param/1";
    // let link = "http://195.181.215.97:8010/s/param/1";

    console.log(link);

    const header = { "Content-Type": "application/json" };
    const cucc = { method: "GET", headers: header };

    console.log(cucc);
    const response = await fetch(link, cucc);
    const responseData = await response.json().then(u=> {

      const param = {
        id: u.id,
        page_of_thank_you: u.page_of_thank_you,
      };
      console.log(param);
      context.commit("setParam", param);
    });

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }
  },

  // async loadDocument(context, payload) {
  //
  //   if (!payload.forceRefresh && !context.getters.shouldUpdate) {
  //     return;
  //   }
  //   let link = `http://195.181.215.97:8000/ipa/docs/${payload.id}`;
  //
  //
  //
  //   const cucc = context.rootGetters.getInit;
  //   const response = await fetch(link, cucc);
  //   const responseData = await response.json();
  //   console.log(response);
  //   if (!response.ok) {
  //     const error = new Error(responseData.message || "Failed to fetch!");
  //     throw error;
  //   }
  //
  //
  //   const doc = {
  //     id: responseData.id,
  //     docName: responseData.nev,
  //     inTxt: responseData.in_txt,
  //     metaData: responseData.metadata,
  //     docCategories: responseData.categories
  //   };
  //
  //   context.commit("setDoc", doc);
  //   context.commit("setFetchTimestamp");
  // },
  // loadDocumentNoCache(context, payload) {
  //   //ha nem telt el elég idő a legutóbbi letöltés óta, nem frissít adatokat
  //   // if (!payload.forceRefresh && !context.getters.shouldUpdate) {
  //   //   return;
  //   // }
  //   let link = `http://195.181.215.97:8000/ipa/docs/${payload.id}`;
  //   let doc;
  //   // if (payload.link) {
  //   //   link = payload.link;
  //   // }
  //   console.log(link);
  //   const axios = require("axios");
  //   // axios.get(link).then(function (response) {
  //   //   // handle success
  //   //   console.log(response);
  //   // });
  //   // fetch(link).then(function(response) {
  //   axios.get(link).then(function(response) {
  //     // console.log (response.json())
  //
  //     // console.log(response)
  //     // const responseData = response.json();
  //     const responseData = response.data;
  //     console.log(response);
  //     // console.log (responseData)
  //     if (!response.statusText === "OK") {
  //       const error = new Error(responseData.statusText || "Failed to fetch!");
  //       throw error;
  //     }
  //
  //
  //     // console.log(responseData)
  //     doc = {
  //       id: responseData.id,
  //       docName: responseData.nev,
  //       inTxt: responseData.in_txt,
  //       metaData: responseData.metadata,
  //       docCategories: responseData.categories
  //     };
  //     // const doc = {
  //     //   id: responseData.id,
  //     //   docName: responseData.nev,
  //     //   inTxt: responseData.in_txt,
  //     //   metaData: responseData.metadata,
  //     //   docCategories: responseData.categories
  //     // };
  //     // console.log(doc);
  //
  //     context.commit("setDoc", doc);
  //     context.commit("setFetchTimestamp");
  //     return doc;
  //   });
  //   console.log(doc);
  // }
};
