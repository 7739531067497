<template>
  <section>
    <base-card>
      <h2>A balatonfüredi Szívkórház elégedettségi kérdőíve</h2>
      <request-form @save-data="saveData" :uid="uid"></request-form>
    </base-card>
  </section>
</template>

<script>


import RequestForm from "@/components/requests/RequestForm";
export default {
  props: ['uid'],
  components: { RequestForm },
  methods: {
    saveData(data) {
      for (const item in data.metadata) {
        data.metadata[item].filler=data.filler;
        this.$store.dispatch('requests/registerRequest', data.metadata[item]);
        //router replace vagy push : elnavigál a címre
        // const z = this.$store.getters['requests/request'];
        // this.$router.replace(`/`);
      }
      //készTODO kell egy dispatch az s/filler/{id}/?fid={uid} oldalra is, minden adattal, plusz a fill mezőt is átállítani
      this.$store.dispatch('requests/registerRequestInFiller', data);
      //TODO valamilyen köszönőoldalra kellene irányítani
      this.$router.replace(`/`);
    }
  }
};
</script>
