export default {
  docs(state) {
    return state.docs;
  },
  doc(state) {
    return state.doc;
  },
  userData(state) {
    return state.userData;
  },
  userParam(state) {
    return state.userParam;
  },
  docsListPageParams(state) {
    return state.docsListPageParams;
  },
  // docsListActualPage: (state, getters)  => {
  //   return getters.docsListPageParams.actualPage;
  // },
  // hasDocs(state) {
  //   return state.docs && state.docs.length > 0;
  // },
  // hasDoc(state) {
  //   return state.doc && state.doc.id !==0;
  // },
  // isCompany(_, getters, _2, rootGetters) {
  //   const companies = getters.companies;
  //   const companyId = rootGetters.companyId;
  //   return companies.some(company => company.id === companyId);
  // },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    //1 percnél régibb?
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  }
};
