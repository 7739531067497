<template>
  <section>
    <base-card>
      <h2>{{ filteredParam }}</h2>
<!--      <doc-form @save-data="saveData"></doc-form>-->
    </base-card>
  </section>
</template>

<script>

// import DocForm from "@/components/docs/DocForm";
export default {
  // components: { DocForm },
  data() {
    return {
      model: [],
      options: [],
      words: "",
     };
  },
  computed: {
    filteredParam() {
      const param = this.$store.getters["docs/userParam"];
      console.log(param);
      return param.page_of_thank_you;
    },
  },
  created() {
    this.loadParam();
  },
  methods: {
    async loadParam() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("docs/loadParam", { }).then(response => this.words=response.page_of_thank_you );
        this.filteredMetadatas();
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
  }
};
</script>
